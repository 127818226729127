@media (max-width: 600px) {
  .App {
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    min-height: 100vh;
  }

  .App a {
    text-decoration: none;
    color: #ffffff;
  }

  .App.dark-mode {
    background-color: #160C28;
  }

  .game-header {
    background-color: #9F71B5;
    min-height: 6vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    color: white;
  }

  .dark-mode .game-header {
    box-shadow: #404040 0px 10px 100px;
  }

  .game-header h1 {
    font-size: 2.5em;
    line-height: 1.4em;
    font-family: 'Lakki Reddy', cursive;
    padding: 15px 0 0 0;
    margin: 15px 0 -20px 0;
  }

  .header-nav-links {
    min-height: 40px;
    padding: 10px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  .game-board {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: space-evenly;
    min-height: 80vh;
  }

  .game-ui {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
  }

  .app-stage-label {
    font-family: 'Open Sans', sans-serif;
    font-size: 0.4em;
    color: #FFEEB8;
  }

  #spicy-options {
    width: 300px;
    padding: 15px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .dark-mode #spicy-options {
    color: #ffffff;
  }

  .play-button {
    max-width: 250px;
    padding: 25px 20px 10px 20px;
    border: none;
    background-color: #6352DD;
    color: #ffffff;
    font-family: 'Lakki Reddy', cursive;
    font-size: 1.6em;
    line-height: 1.0em;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    cursor: pointer;
  }

  .play-button:hover {
    background-color: #433893;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgba(0, 0, 0, 0.23) 0px 1px 4px;;
  }

  .dark-mode .play-button:hover {
    background-color: #433893;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgba(0, 0, 0, 0.23) 0px 1px 4px;;
  }

  .cards {
    margin: 0 5vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .game-card {
    max-width: 360px;
    border-radius: 3%;
    box-shadow: rgba(199, 199, 206, 0.25) 0px 6px 12px -2px, rgba(97, 97, 97, 0.3) 0px 3px 7px -3px;
  }

  .card-text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 360px;
    height: 504px;
    padding: 0 20px 20px 20px;
    margin: 0 0 7px 0;
    border-radius: 3%;
    color:rgb(0, 0, 0);
  }

  .main-card-content {
    display: flex;
    flex-direction: column;
  }

  .pro .card-text {
    background-color: #9FC8A0;
    border: solid 5px #FFF8FF;
    box-shadow: rgba(199, 199, 206, 0.25) 0px 6px 12px -2px, rgba(97, 97, 97, 0.3) 0px 3px 7px -3px;
  }

  .dark-mode .pro .card-text {
    background-color: #9FC8A0;
  }

  .pro .card-header {
    background-color: #3E663F;
  }

  .ohno .card-text {
    background-color: #B0ADEA;
    border: solid 5px #FFF8FF;
    box-shadow: rgba(199, 199, 206, 0.25) 0px 6px 12px -2px, rgba(97, 97, 97, 0.3) 0px 3px 7px -3px;
  }

  .dark-mode .ohno .card-text {
    background-color: #B0ADEA;
  }

  .ohno .card-header {
    background-color: #474394;
  }

  .card-text ul {
    text-align: left;
    list-style: url(./Images/bullet-icon.png);
    padding: 0 0 0 10px;
  }

  .card-text li {
    margin: 5px;
    line-height: 1.3em;
  }

  .card-text h2 {
    margin: 0;
    line-height: 1.2em;
  }

  .card-text h4 {
    font-family: 'Lakki Reddy', cursive;
    font-size: 1.5em;
    margin: 0;
  }

  .card-header {
    height: 60px;
    padding: 16px 0 24px 0;
    margin: 0 -20px 10px -20px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    color: #ffffff;
  }

  .card-attribution p {
    margin: 0 0 -10px 0;
    font-size: 0.8em;
    opacity: 60%;
  }

  .redraw-cards-buttons {
    margin: 20px 15vw;
    width: 70vw;
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }

  .redraw-cards-buttons button {
    background-color: #F17B80;
    font-size: 1.2em;
    width: 200px;
    padding: 15px 15px 5px 15px;
  }

  .draw-card-button {
    border: none;
    color: #ffffff;
    font-family: 'Lakki Reddy', cursive;
    line-height: 1.0em;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    cursor: pointer;
  }

  .draw-card-button:hover {
    background-color: #dd696f;
  }

  .create-a-card {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 0 10px;
  }

  .create-a-card .pro,.create-a-card .ohno {
    padding-top: 100px;
  }

  .card-preview {
    color: rgba(255, 255, 255, 0.6)
  }

  .admin-login {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    min-height: 80vh;
    padding: 100px 50px;
  }

  .admin-login-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    width: 400px;
  }

  .dialog-card-preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .dialog-card-preview .pro,.dialog-card-preview .ohno {
    padding-top: 30px;
  }

  .dialog-card-preview .pro .card-text {
    background-color: #fdc05d;
  }

  .dialog-card-preview .ohno .card-text {
    background-color: #bd72fb;
  }

  .closed-beta-page, .about-page {
    min-height: 65vh;
    margin: 60px 0;
  }

  .closed-beta-page h1 {
    font-family: 'Lakki Reddy', cursive;
    font-size: 2.2em;
    margin: 0;
  }

  .closed-beta-page a {
    margin: 20px;
  }

  .about-page {
    margin: 60px 0;
  }

  .about-page h1 {
    font-family: 'Lakki Reddy', cursive;
    font-size: 2.2em;
    margin: 0;
  }

  .about-content {
    text-align: left;
    padding: 10px;
  }

  #footerContainer {
    height: 80px;
  }

  #footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    bottom: 0;
    width: 100%;
    height: 80px;
    padding: 40px 0;
    margin: 40px 0 0 0;
    background: rgb(22,12,40);
    background: linear-gradient(180deg, rgba(22,12,40,1) 0%, rgba(33,21,56,1) 40%, rgba(43,28,69,1) 80%, rgba(43,30,71,1) 100%);
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

  #footer h3 {
    color: #ffffff;
    font-weight: 400;
    margin: 30px;
  }

  .front-page.row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 30px 0;
  }

  .front-page img {
    max-width: 70vw;
  }

  .front-page-hero {
    height: 360px;
    margin-top: -2px;
    background: rgb(159,113,181);
    background: linear-gradient(180deg, rgba(159,113,181,1) 0%, rgba(162,117,184,1) 15%, rgba(188,151,206,1) 70%, rgba(230,204,242,1) 100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .front-page-hero-headline {
    font-family: 'Lakki Reddy', cursive;
  }

  .front-page-hero h1 {
    font-size: 6em;
    line-height: 1em;
    margin: 20px 0;
  }

  .front-page-hero p {
    font-size: 1.7em;
    margin: 0;
  }

  .front-page-hero-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 40px;
  }

  .step-two-desktop {
    display: none;
  }

  .about-team {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1em;
  }

  .faqs a {
    color: #160C28;
  }

}

@media (min-width: 600px) {

  * {
    box-sizing: border-box;
  }

  .App {
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    min-height: 100vh;
  }

  .App a {
    text-decoration: none;
    color: #ffffff;
  }

  .App.dark-mode {
    background-color: #160C28;
  }

  .game-header {
    background-color: #9F71B5;
    min-height: 6vh;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    color: white;
  }

  .dark-mode .game-header {
    box-shadow: #404040 0px 10px 100px;
  }

  .game-header h1 {
    font-size: 2.5em;
    line-height: 1.4em;
    font-family: 'Lakki Reddy', cursive;
    padding: 15px 0 0 0;
    margin: 15px 0 0 40px;
  }

  .header-nav-links {
    min-height: 40px;
    padding: 30px 40px 10px 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
  }

  .game-board {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: space-evenly;
    min-height: 80vh;
  }

  .game-ui {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
  }

  .app-stage-label {
    font-family: 'Open Sans', sans-serif;
    font-size: 0.4em;
    color: #FFEEB8;
  }

  #spicy-options {
    width: 300px;
    padding: 15px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .dark-mode #spicy-options {
    color: #ffffff;
  }

  .play-button {
    max-width: 250px;
    padding: 25px 20px 10px 20px;
    border: none;
    background-color: #6352DD;
    color: #ffffff;
    font-family: 'Lakki Reddy', cursive;
    font-size: 1.6em;
    line-height: 1.0em;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    cursor: pointer;
  }

  .play-button:hover {
    background-color: #433893;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgba(0, 0, 0, 0.23) 0px 1px 4px;;
  }

  .dark-mode .play-button:hover {
    background-color: #433893;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgba(0, 0, 0, 0.23) 0px 1px 4px;;
  }

  .cards {
    margin: 0 20vw;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  .game-card {
    max-width: 360px;
    max-height: 504px;
    border-radius: 3%;
    box-shadow: rgba(199, 199, 206, 0.25) 0px 6px 12px -2px, rgba(97, 97, 97, 0.3) 0px 3px 7px -3px;
  }

  .game-card:hover {
    box-shadow: rgba(180, 180, 180, 0.19) 0px 10px 20px, rgba(180, 180, 180, 0.23) 0px 6px 6px;
  }

  .card-text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 360px;
    height: 504px;
    padding: 0 20px 20px 20px;
    margin: 0 0 7px 0;
    border-radius: 3%;
    color:rgb(0, 0, 0);
  }

  .main-card-content {
    display: flex;
    flex-direction: column;
  }

  .pro .card-text {
    background-color: #9FC8A0;
    border: solid 5px #FFF8FF;
    box-shadow: rgba(199, 199, 206, 0.25) 0px 6px 12px -2px, rgba(97, 97, 97, 0.3) 0px 3px 7px -3px;
  }

  .dark-mode .pro .card-text {
    background-color: #9FC8A0;
  }

  .pro .card-header {
    background-color: #3E663F;
  }

  .ohno .card-text {
    background-color: #B0ADEA;
    border: solid 5px #FFF8FF;
    box-shadow: rgba(199, 199, 206, 0.25) 0px 6px 12px -2px, rgba(97, 97, 97, 0.3) 0px 3px 7px -3px;
  }

  .dark-mode .ohno .card-text {
    background-color: #B0ADEA;
  }

  .ohno .card-header {
    background-color: #474394;
  }

  .card-text ul {
    text-align: left;
    list-style: url(./Images/bullet-icon.png);
    padding: 0 0 0 10px;
  }

  .card-text li {
    margin: 5px;
    line-height: 1.3em;
  }

  .card-text h2 {
    margin: 0;
    line-height: 1.2em;
  }

  .card-text h4 {
    font-family: 'Lakki Reddy', cursive;
    font-size: 1.5em;
    margin: 0;
  }

  .card-header {
    height: 60px;
    padding: 16px 0 24px 0;
    margin: 0 -20px 10px -20px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    color: #ffffff;
  }

  .card-attribution p {
    margin: 0 0 -10px 0;
    font-size: 0.8em;
    opacity: 60%;
  }

  .redraw-cards-buttons {
    margin: 20px 15vw;
    width: 70vw;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  .redraw-cards-buttons button {
    background-color: #F17B80;
    font-size: 1.2em;
    width: 200px;
    padding: 15px 15px 5px 15px;
  }

  .draw-card-button {
    border: none;
    color: #ffffff;
    font-family: 'Lakki Reddy', cursive;
    line-height: 1.0em;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    cursor: pointer;
  }

  .draw-card-button:hover {
    background-color: #dd696f;
  }

  .create-a-card {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 0 60px;
  }

  .create-a-card .pro,.create-a-card .ohno {
    padding-top: 100px;
  }

  .card-preview {
    color: rgba(255, 255, 255, 0.6)
  }

  .admin-login {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    min-height: 80vh;
    padding: 100px 50px;
  }

  .admin-login-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    width: 400px;
  }

  .dialog-card-preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .dialog-card-preview .pro,.dialog-card-preview .ohno {
    padding-top: 30px;
  }

  /* .dialog-card-preview .pro .card-text {
    background-color: #fdc05d;
  } */

  /* .dialog-card-preview .ohno .card-text {
    background-color: #bd72fb;
  } */

  .closed-beta-page, .about-page {
    min-height: 60vh;
    margin: 80px 0;
  }

  .closed-beta-page h1 {
    font-family: 'Lakki Reddy', cursive;
    font-size: 3em;
    line-height: 1em;
    margin: 10px 0;
  }

  .closed-beta-page a {
    margin: 20px;
  }

  .about-page h1 {
    font-family: 'Lakki Reddy', cursive;
    font-size: 3em;
    margin: 10px 0;
  }

  .about-content {
    text-align: left;
    padding: 20px 40px;
  }

  #footerContainer {
    height: 80px;
  }

  #footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    bottom: 0;
    width: 100%;
    height: 80px;
    padding: 40px 0;
    margin: 40px 0 0 0;
    background: rgb(22,12,40);
    background: linear-gradient(180deg, rgba(22,12,40,1) 0%, rgba(33,21,56,1) 40%, rgba(43,28,69,1) 80%, rgba(43,30,71,1) 100%);
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

  #footer h3 {
    color: #ffffff;
    font-weight: 400;
    margin: 30px;
  }

  .front-page.row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin: 60px 0;
  }

  .front-page img {
    max-width: 350px;
  }

  .front-page .card-heading {
    max-width: 300px;
  }

  .front-page-hero {
    height: 400px;
    background: rgb(159,113,181);
    background: linear-gradient(180deg, rgba(159,113,181,1) 0%, rgba(162,117,184,1) 15%, rgba(188,151,206,1) 70%, rgba(230,204,242,1) 100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .front-page-hero-headline {
    font-family: 'Lakki Reddy', cursive;
    line-height: 1.4em;
  }

  .front-page-hero h1 {
    font-size: 6em;
  }

  .front-page-hero p {
    font-size: 1.7em;
  }

  .front-page-hero-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 60px;
  }

  .step-two-mobile {
    display: none;
  }

  .about-team {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1em;
  }

  .faqs a {
    color: #160C28;
  }

}